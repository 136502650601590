<template>
    <div class="video-detail">
        <div class="videos-header">
            <v-breadcrumb :index="index" :child="child"></v-breadcrumb>
        </div>
        <div class="video-detail-body">
            <Row class="section-list-item" type="flex">
                <Col span="16">
                <div class="video-player">
                    <video ref="videoPlayer" class="video-js vjs-16-9"></video>
                </div>
                <div class="video-player-title" v-for="video in options.sources">
                    <div class="video-post">
                        <span class="video-name">
                            {{ `${video.speaker}-${video.title}` }}
                        </span>
                    </div>
                </div>
                </Col>
                <Col span="8">
                <div class="videos-recommend-pl80">
                    <!-- <div class="videos-recommend-title">
                            <span class="videors-recommend-bd">推荐观看</span>
                        </div> -->
                    <div ref="optiscroll" class="videos-recommend-scroll optiscroll">
                        <div class="optiscroll-inner">
                            <div class="scroll-item" v-for="item in list" :key="item._id" @click="playVideo(item)">
                                <img class="scroll-img" :src="item.src" :alt="item.title" />
                                <span class="scroll-title">{{
                                    item.title
                                }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                </Col>
            </Row>
        </div>
        <div class="videos-detail-infos">
            <div class="videos-detail-info-item">
                <span class="videos-detail-info-item-label">合集名称：</span>
                <span class="videos-detail-info-item-value">{{
                    infos.coursePack
                }}</span>
            </div>
            <div class="videos-detail-info-item">
                <span class="videos-detail-info-item-label">合集简介：</span>
                <span class="videos-detail-info-item-value">{{
                    infos.remark
                }}</span>
            </div>
            <div class="videos-detail-info-item">
                <span class="videos-detail-info-item-label">创建时间：</span>
                <span class="videos-detail-info-item-value">{{
                    infos.writeAt
                }}</span>
            </div>
            <div class="videos-detail-info-item">
                <span class="videos-detail-info-item-label">视频名称：</span>
                <span class="videos-detail-info-item-value">{{
                    infos.title
                }}</span>
            </div>
            <div class="videos-detail-info-item">
                <span class="videos-detail-info-item-label">视频简介：</span>
                <span class="videos-detail-info-item-value">{{
                    infos.introduce
                }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { MethodsUtil } from "../../assets/util/method.js";
import { mapState } from "vuex";
import VBreadcrumb from "../../compontents/base/VBreadcrumb.vue";
import qs from "query-string";
import videojs from "video.js";
import Optiscroll from "optiscroll";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { DayUtil } from "@/assets/util/day.js";
import { httpURL } from "../../assets/util/http";
export default {
    name: "VideoDetail",
    components: { VBreadcrumb, swiper, swiperSlide },
    data() {
        const { id, type } = this.$route.params;
        // query中的参数
        const { cId } = this.$route.query;

        return {
            id: type == "coCourse" ? id : cId, // id，表示合集的id,
            cId: type == "coCourse" ? "" : id, // 视频的id
            type,
            index: "",
            child: "",
            options: {
                autoplay: true,
                controls: true,
                playbackRates: [1, 1.5, 2, 4],
                sources: [],
            },
            infos: {},
            player: null,
            scroll: null,
            list: [],
        };
    },
    computed: {
        ...mapState(["admin"]),
    },
    mounted() {
        const dom = this.$refs.optiscroll;
        this.myOptiscrollInstance = new Optiscroll(dom, {
            forceScrollbars: true,
        });
        let { index, child } = MethodsUtil.getRouteIndexAndChild(this.$route);
        this.index = index;
        this.child = child;
    },
    beforeDestroy() {
        if (this.player) {
            this.player.dispose();
        }
    },
    created() {
        debugger;
        // this.fetchVideoDetail(this.id);
        if (this.type == "coCourse" || this.id) {
            this.fetchVideoList();
        } else {
            this.fetchVideoDetail(this.cId);
        }
    },
    methods: {
        async playVideo(rowData) {
            await this.fetchVideoDetail(rowData._id, rowData);
        },
        async fetchVideoList() {
            // GET /api/information/courseSearch?pageSize=10&pageNum=0&year=2022&packId=合集id&keywords=关键词&sort=-createdAt
            const baseUrl = "/information/courseSearch";
            const articles = qs.stringify({
                pageSize: 12,
                pageNum: 0,
                year: "",
                packId: this.id,
                keywords: "",
                sort: "-writeAt,-updatedAt"
            });

            const articlesUrl = `${baseUrl}?${articles}`;
            let res = await this.$http.get(articlesUrl);
            if (res.result && res.result.length) {
                this.list = res.result.map((item) => {
                    const { cover, title } = item;
                    return { src: cover && cover.src, title, ...item };
                });
                // 播放选中的视频
                const result = res.result.filter(
                    (item) => item._id == this.cId
                );
                let video = result[0];
                if (video == null || video == "") {
                    video = res.result[0];
                }
                this.fetchVideoDetail(video._id, video);
            }
        },
        async fetchVideoDetail(id, video) {
            const articlesUrl = `/information/course/${id}`;
            let resp = await this.$http.get(articlesUrl);

            if (resp && resp.playInfo) {
                const { coursePack: pp } = video || {};
                const {
                    title,
                    playInfo,
                    teacher,
                    auth,
                    coursePack: pop,
                    createdAt,
                    writeAt
                } = resp;
                if (auth == true && this.admin == null) {
                    // this.$router.push('/common/login');
                    window.open(`${httpURL}/user/auth`);
                    return;
                }
                this.infos = {
                    title,
                    introduce: (video && video.introduce) || "",
                    coursePack: (pop && pop.name) || "",
                    remark: (pop && pop.remark) || "",
                    createdAt: DayUtil.fmtDate(createdAt),
                    writeAt:DayUtil.fmtDate(writeAt),
                };
                this.options.sources = [
                    {
                        title,
                        postAt: DayUtil.fmtDate(createdAt),
                        src: playInfo && playInfo.playUrl,
                        speaker: teacher || (video && video.teacher),
                    },
                ];
                this.$nextTick(() => {
                    if (this.player) {
                        this.player.src({
                            src: (playInfo && playInfo.playUrl) || "",
                        });
                        return;
                    }
                    this.player = videojs(
                        this.$refs.videoPlayer,
                        this.options,
                        function onPlayerReady() {
                            console.log("onPlayerReady", this);
                        }
                    );
                });
            }
        },
        async fetchVideoData(id) {
            const articlesUrl = `/information/playInfo?guid=${id}`;
            let resp = await this.$http.get(articlesUrl);
        },
    },
};
</script>

<style lang="less">
.videos-detail-infos {
    width: 90%;
    margin: 32px auto 48px;

    .videos-detail-info-item {
        display: block;
        line-height: 2;
        font-size: 14px;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
    }

    .videos-detail-info-item-label {
        display: inline-block;
        width: 80px;
        margin-right: 8px;
        text-align: left;
        font-weight: 700;
        vertical-align: top;
    }

    .videos-detail-info-item-value {
        display: inline-block;
        white-space: normal;
        width: calc(100% - 90px);
    }
}

.video-detail {
    max-width: 2580px;
    margin: auto;

    .video-detail-body {
        width: 90%;
        margin: 48px auto 0;
    }

    .video-player {
        position: realtive;
    }

    .video-js {
        position: realtive;
    }
}

.section-list-item {
    background-color: #f7f9fa;
    margin: 8px;
    padding: 16px;
}

.video-player-title {
    text-align: left;
    padding: 16px 0;

    .video-name {
        font-weight: 500;
        font-size: 14px;
        margin-right: 16px;
    }

    .video-post {
        display: inline-block;
    }

    .video-post-time {
        font-size: 12px;
    }

    .video-post-speaker {
        font-size: 12px;
    }
}

.video-player {
    .vjs-big-play-button {
        left: 12% !important;
        top: 92% !important;
        margin-left: -2em;
        margin-top: -1.3em;
    }
}

.videos-recommend-pl80 {
    margin-left: 24px;
    height: 100%;
    position: relative;

    .videos-recommend-title {
        text-align: left;
        padding-bottom: 16px;

        .videors-recommend-bd {
            border: 1px solid #ccc;
            font-weight: bold;
            font-size: 16px;
            padding: 8px;
            display: inline-block;
        }
    }

    .videos-recommend-scroll {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 53px;
        top: 0;
        overflow: hidden;

        .scroll-item {
            overflow: hidden;
            font-size: 0;
            text-align: left;
            cursor: pointer;
            padding: 8px 4px;
            border: 1px solid transparent;
            position: realtive;
            word-break: break-word;

            &:hover {
                border: 1px solid #ccc;
                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.14);
            }
        }

        .scroll-img {
            width: 132px;
            height: 76px;
            margin-right: 8px;
            float: left;
        }

        .scroll-title {
            font-size: 14px;
            display: block;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            max-height: 76px;
            overflow: hidden;
        }
    }
}

.videos-recommend-scroll {
    .swiper-container {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
    }

    .swiper-container-vertical>.swiper-wrapper {
        flex-direction: column;
    }
}
</style>
